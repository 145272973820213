import "../styles/layouts/foodservices.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import BannerDownloadApp from "../components/BannerDownladApp/BannerDownloadApp";
import BrandsFoodServices from "../components/Brands/BrandsFoodServices";
import HeroService from "../components/Hero/HeroService/HeroService";
import Layout from "../components/Layout/layout";
import OurTargetLogistics from "../components/OurTarget/OurTargetLogistics";
import { SEO } from "../components/SEO/SEO";
import ServiceMarkupSchema from "../components/SEO/ServiceMarkupSchema";
import ServicesResume, { ServiceResume } from "../components/ServicesResumen/ServicesResume";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import Video from "../components/Video/Video";
import { Problem } from "../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../components/WeTalk/WeTalk";
import { Image, Photo, Slug } from "../types/shared";

const FoodServicesPage = ({ data }: PageProps<Queries.FoodServicesPageQuery>) => {
  const photoHero = data.sanityPageFoodServices?.hero?.photo as Photo;
  const servicesResumenList: ServiceResume[] = [];

  data.allSanityTemplateFoodServicesServices.nodes.forEach((service) => {
    if (!service.hero?.title || !service.seo?.thumbnail) {
      return;
    }
    servicesResumenList.push({
      name: service.hero.title,
      photo: { image: service.seo.thumbnail, alt: `Foto de portada de ${service.hero.title}` } as unknown as Photo,
      slug: { current: `/distribucion-comercial/${service.slug?.current}/` } as Slug,
    });
  });
  if (data.sanityPageOurProducts?.seo?.thumbnail && data.sanityPageOurProducts?.hero?.title) {
    servicesResumenList.push({
      name: data.sanityPageOurProducts.hero.title,
      photo: {
        image: data.sanityPageOurProducts.seo.thumbnail,
        alt: `Foto de portada de ${data.sanityPageOurProducts.hero.title}`,
      } as unknown as Photo,
      slug: { current: `/distribucion-comercial/${data.sanityPageOurProducts.slug?.current}/` } as Slug,
    });
  }

  if (data.sanityPageApp?.seo?.thumbnail) {
    const serviceApp: ServiceResume = {
      slug: { current: `/app-usicom/` },
      name: `Usicom`,
      photo: { image: data.sanityPageApp?.seo?.thumbnail, alt: `Foto de portada de Usicom` } as unknown as Photo,
    };
    servicesResumenList.push(serviceApp);
  }

  const has_video = !!data.sanityPageFoodServices?.video?.code;
  let videoThumbnail: Photo | null = null;
  if (has_video) {
    const alt = data.sanityPageFoodServices.video?.alt ? data.sanityPageFoodServices.video?.alt : "";
    videoThumbnail = {
      image: data.sanityPageFoodServices.video?.image as unknown as Image,
      alt,
    };
  }

  return (
    <Layout>
      <div className="l-logistic">
        <HeroService
          title={data.sanityPageFoodServices?.hero?.title}
          accompanimentTitle={data.sanityPageFoodServices?.hero?.resume}
          subtitle={data.sanityPageFoodServices?.hero?.subtitle}
          resume={data.sanityPageFoodServices?.hero?.text}
          photo={photoHero}
        />
        <ServicesResume
          servicesList={servicesResumenList}
          is_firstBlue={true}
        />
        <BrandsFoodServices />
        <OurTargetLogistics />
        {has_video && (
          <Video
            code={data.sanityPageFoodServices.video.code}
            photo={videoThumbnail}
          />
        )}
        <BannerDownloadApp />
        <WeKnowYourProblems
          title={data.sanityPageFoodServices?.weKnowYourProblems?.title}
          problemsList={data.sanityPageFoodServices?.weKnowYourProblems?.PAQsList as Problem[]}
        />
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default FoodServicesPage;

export const Head = ({ data }: PageProps<Queries.FoodServicesPageQuery>) => (
  <>
    <SEO
      title={data.sanityPageFoodServices?.seo?.title}
      robots={data.sanityPageFoodServices?.seo?.seo_robots}
      description={data.sanityPageFoodServices?.seo?.description}
      canonical={data.sanityPageFoodServices?.seo?.canonical}
      imageAbsolutePath={data.sanityPageFoodServices?.seo?.thumbnail?.asset?.url}
    />
    <ServiceMarkupSchema
      titleHero={data.sanityPageFoodServices?.hero?.title}
      descriptionService={data.sanityPageFoodServices?.seo?.description}
      absolutePathImageOG={data.sanityPageFoodServices?.seo?.thumbnail?.asset?.url}
      absolutePathService={`${data.sanityDocumentData?.urlSite}distribucion-comercial`}
      problemList={data.sanityPageFoodServices?.weKnowYourProblems?.PAQsList as Problem[]}
    />
  </>
);

export const query = graphql`
  query FoodServicesPage {
    sanityDocumentData {
      urlSite
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    sanityPageApp {
      seo {
        thumbnail {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
    }
    sanityPageFoodServices {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      hero {
        title
        text: _rawText
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        subtitle
        resume
      }
      video {
        code
        alt
        image {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
      weKnowYourProblems {
        title
        PAQsList {
          question
          answer: _rawAnswer
        }
      }
    }
    sanityPageOurProducts {
      seo {
        thumbnail {
          asset {
            gatsbyImageData
            _id
          }
        }
      }
      hero {
        title
      }
      slug {
        current
      }
    }
    allSanityTemplateFoodServicesServices {
      nodes {
        seo {
          thumbnail {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
        hero {
          title
        }
        slug {
          current
        }
      }
    }
  }
`;
